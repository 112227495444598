import { GbBase } from "./gb-base";

export class GbContent extends GbBase {
  getUrls() {
    return {
      getSecurityStmtContentsURL:
        this.getBaseUrl() +
        "uws/gb-content-service/caas/v1/assets/Security%20Statement?" +
        this.getClientId("auth"),
      siteMapUrl:
        this.getBaseUrl() + "uws/gb-content-service/caas/v1/sites?" + this.getClientId("auth"),
      unAuthSiteMapUrl:
        this.getBaseUrl() + "uws/gb-content-service/caas/v1/sites?" + this.getClientId("auth"),
      assetsByPageId_unauth:
        this.getBaseUrl() +
        "uws/gb-content-service/caas/v1/assets/byid/{pageId}?flatten=true&" +
        this.getClientId("auth"),
      assetsByPageId:
        this.getBaseUrl() +
        "uws/gb-content-service/caas/v1/assets/byid/{pageId}?flatten=true&" +
        this.getClientId("auth"),
      getSystemDownTime:
        this.getBaseUrl() + "uws/gb-content-service/caas/v1/sysMessage?" + this.getClientId("auth"),
      getSystemDownTime_unauth:
        this.getBaseUrl() + "uws/gb-content-service/caas/v1/sysMessage?" + this.getClientId("auth"),
      featuresTurnOnOff_auth:
        this.getBaseUrl() +
        "uws/gb-content-service/caas/v1/sysDownMessage?" +
        this.getClientId("auth") +
        "&pageId=",
      leaveLibraryurl:
        this.getBaseUrl() +
        "uws/gb-content-service/caas/v1/forms/formName=ll?" +
        this.getClientId("auth"),
      missingItemCaasURL:
        this.getBaseUrl() +
        "uws/gb-content-service/caas/v1/forms/formName=mi?" +
        this.getClientId("auth"),
    };
  }
}
