import { GbBase } from "./gb-base";

export class GbIntake extends GbBase {
  getUrls(): Record<string, string> {
    return {
      getMedicalConditions:
        this.getBaseUrl() +
        "gb-intake-service/intake/v1/medical-conditions/category?" +
        this.getClientId("auth"),
    };
  }
}
