import { EnvironmentLoader } from "@env/environment-loader";
import { loadManifest } from "@angular-architects/module-federation";
export const env: any = EnvironmentLoader.getEnvironmentFile();

// Function to get the environment name from the host
function getEnvironmentFromHost() {
  return env.envName === "prod" ? env.envName : env.envName + "-";
}

// Function to modify the manifest file by replacing the {ENV} placeholder with the actual environment name
async function modifyManifest() {
  try {
    // Fetch the manifest file
    const response = await fetch("/assets/mfe-manifest.json");
    const manifest = await response.json();
    const env = getEnvironmentFromHost();

    // Replace the {ENV} placeholder in the remoteEntry URLs with the actual environment name
    Object.keys(manifest).forEach((key) => {
      manifest[key] = manifest[key].remoteEntry.replace("{ENV}-", `${env}`);
    });

    console.log("Modified manifest:", manifest);
    return manifest;
  } catch (err) {
    console.error("Error loading manifest:", err);
    throw err;
  }
}

// Modify the manifest and load it
modifyManifest()
  .then((modifiedManifest) => {
    // Create a blob URL for the modified manifest
    const blob = new Blob([JSON.stringify(modifiedManifest)], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Load the manifest using the blob URL
    return loadManifest(url);
  })
  .then((_) => import("./bootstrap")) // Import the bootstrap module
  .catch((err) => console.error("Error during bootstrap:", err)); // Handle errors during bootstrap
