import { GbBase } from "./gb-base";

export class GbCiamBase extends GbBase {
  private ciamAccountUrl: string;
  private envNameForCIAM: string;
  private ciamAppId: string;
  private sfdAccountUrl: string;
  private ciamApiSgwUrl: string;
  constructor(
    clientId: Map<string, string>,
    baseUrl: string,
    envName: string,
    ciamAccountUrl: string,
    sfdAccountUrl: string,
    ciamApiSgwUrl: string,
  ) {
    super(clientId, baseUrl, envName);
    this.ciamAccountUrl = ciamAccountUrl;
    this.envNameForCIAM = envName;
    this.sfdAccountUrl = sfdAccountUrl;
    this.ciamApiSgwUrl = ciamApiSgwUrl;
  }

  getClientId(id) {
    let targetEnv: string;
    if (this.getEnvName() in this.baseEnvConfig) {
      if (this.baseEnvConfig[this.getEnvName().toLowerCase()]["mbTargetEnv"] === "") {
        targetEnv = "";
      } else {
        targetEnv =
          "&target_env=" + this.baseEnvConfig[this.getEnvName().toLowerCase()]["mbTargetEnv"];
      }
    } else {
      targetEnv = "";
    }

    return "client_id=" + this.getClient(id) + targetEnv;
  }
  getCiamAccountUrl() {
    return this.ciamAccountUrl;
  }

  getCiamAccountLoginUrl() {
    const targetEnv = this.getEnvName();

    if (targetEnv in this.baseEnvConfig) {
      return this.ciamAccountUrl + this.baseEnvConfig[targetEnv.toLowerCase()]["ciamLoginUrl"];
    } else {
      return this.ciamAccountUrl + this.baseEnvConfig.prod.ciamLoginUrl;
    }
  }

  getCiamAccountRegistrationUrl() {
    const targetEnv = this.getEnvName();

    if (targetEnv in this.baseEnvConfig) {
      return (
        this.ciamAccountUrl + this.baseEnvConfig[targetEnv.toLowerCase()]["ciamRegistrationUrl"]
      );
    } else {
      return this.ciamAccountUrl + this.baseEnvConfig.prod.ciamRegistrationUrl;
    }
  }
  getSfdAccountUrl() {
    return this.sfdAccountUrl;
  }

  getEnvName() {
    return this.envNameForCIAM;
  }

  getCiamAppId() {
    const targetEnv = this.getEnvName();

    if (targetEnv in this.baseEnvConfig) {
      return this.baseEnvConfig[targetEnv.toLowerCase()]["appId"];
    } else {
      return this.baseEnvConfig.prod.appId;
    }
  }

  getCiamApiSgwUrl() {
    return this.ciamApiSgwUrl;
  }

  getCiamSgwClientId(id) {
    let ciamSgwTargetEnv: string;
    if (this.getEnvName() in this.baseEnvConfig) {
      if (this.baseEnvConfig[this.getEnvName().toLowerCase()]["ciamApiSgwTargetEnv"] === "") {
        ciamSgwTargetEnv = "";
      } else {
        ciamSgwTargetEnv =
          "&target_env=" +
          this.baseEnvConfig[this.getEnvName().toLowerCase()]["ciamApiSgwTargetEnv"];
      }
    } else {
      ciamSgwTargetEnv = this.baseEnvConfig.prod.ciamApiSgwTargetEnv;
    }

    return "client_id=" + this.getClient(id) + ciamSgwTargetEnv;
  }
}
